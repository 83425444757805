@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: AcademySans;
    src: url("/public/fonts/AcademySans/AcademySans-Light.woff") format("woff");
    font-weight: 300;
  }

  @font-face {
    font-family: AcademySans;
    src: url("/public/fonts/AcademySans/AcademySans.woff") format("woff");
    font-weight: 400;
  }

  @font-face {
    font-family: AcademySans;
    src: url("/public/fonts/AcademySans/AcademySans-DemiBold.woff") format("woff");
    font-weight: 500;
  }

  @font-face {
    font-family: AcademySans;
    src: url("/public/fonts/AcademySans/AcademySans-Bold.woff") format("woff");
    font-weight: 700;
  }
}
